.main-button {
  background-color: #55198b;
  border: 1px solid #55198b;
  color: #fff;
  font-weight: 700;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 13px 22px;
  margin-right: 50px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all .3s ease-in-out 0s;
}
.main-button:hover {
  background-color: #ffffff;
  color: #55198b;
  transition: all .3s ease 0s;
  transform: translateY(-3px);
}
.project-button{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.project-button > .main-button{
  margin-right: 0 !important;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 10.5px;
    padding: 8px 8px;
    margin-right: 0;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 9.5px;
    padding: 4px 6px;
    margin-right: 0;
  }
}
