.card-image {
    max-width: 100%;
    height: auto;
    width: 250px;
}
.card-title {
    color: #000000;
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    margin: 15px 0 0 0;
}
.card-subtitle {
    color: #666666;
    font-size: 17px;
    line-height: 1.5rem;
}
.certificate-card {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
.certificate-detail-div {
    text-align: center;
}
.certificate-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}
.certificate-image-div {
    position: relative;
    height: 250px;
    overflow: hidden;
    text-align: center;
}
.project-cards-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem 1rem;
}
.certificate-card-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.certificate-card-footer span.certificate-tag {
    background: #55198b;
    color: #f5f2f4;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0 0.75em;
    cursor: pointer;
    transition: 0.2s ease-in;
}
span.certificate-tag:hover {
    background: #8c43ce;
}
